import React from 'react'

import * as Config from '@arch-log/webapp.modules/config/components'
import * as Routes from '@arch-log/webapp.shared/Routes'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as ProjectErrors from '@arch-log/webapp.modules/project.v2/errors'
import * as ProjectListEntry from '@arch-log/webapp.shared/Project.v2/List/Entry'
import * as CloneForm from 'modules/project/components/CloneForm'
import * as Fields from './Fields'
import * as Containers from './Containers'
import * as Layout from './Layout'
import * as Modal from '@arch-log/webapp.atomics/components/Modal'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'
import { useAsync } from 'react-use'

/**
 */
export const Init = ({ next }) => {
  React.useEffect(() => {
    next({
      defaultValues: { includeBoards: false, includeFts: false },
    })
  }, [])

  return null
}

/**
 */
export const Input = ({ project, next, context, onCancel }) => {
  return (
    <CloneForm.Initializer
      onSubmit={(data) => next({ formData: data })}
      defaultValues={context?.defaultValues}
      values={context?.formData}
      errors={context?.errors}
    >
      <Layout.Container>
        <Layout.Body>
          <Containers.Description />
          <Containers.FormErros />
          <Layout.FormRow>
            <Fields.IncludeBoards />
            <Config.IfFeatureEnabled name={'ft'}>
              <Fields.IncludeFts />
              <Containers.NoticeIncludeImages />
            </Config.IfFeatureEnabled>
          </Layout.FormRow>
          <Layout.BodySection>
            <Project.Remote.Queries.EntryByUuid.Loader
              id={project.uuid}
              components={{ Loading: () => <>Loading</> }}
            >
              <ProjectListEntry.Default />
            </Project.Remote.Queries.EntryByUuid.Loader>
          </Layout.BodySection>
        </Layout.Body>
        <Layout.Footer>
          <CloneForm.Submit render={Containers.SubmitButton} />
          <Containers.CancelButton onCancel={onCancel} />
        </Layout.Footer>
      </Layout.Container>
    </CloneForm.Initializer>
  )
}

/**
 */
const ProgressStep = ({ next: subNext, project, context, setContext }) => {
  const [cloneEntry] = Project.Remote.Mutations.CloneEntry.useMutation()
  const { hide } = React.useContext(Modal.Context)

  useAsync(async () => {
    try {
      await cloneEntry({
        variables: {
          sourceId: project.uuid.toString(),
          includeBoards: context?.formData?.includeBoards,
          includeFts: context?.formData?.includeFts,
        },
        onCompleted: (data) => {
          subNext({ clonedEntry: data?.clonedEntry })
        },
        onError: (error) => {
          switch (error.message) {
            case ProjectErrors.FailedToCloneBoards:
              setContext({
                errors: {
                  action: `Action.Error.${ProjectErrors.FailedToCloneBoards}`,
                },
              })

              break
            case ProjectErrors.FailedToCloneFts:
              setContext({
                errors: {
                  action: `Action.Error.${ProjectErrors.FailedToCloneFts}`,
                },
              })

              break
            case ProjectErrors.Failed:
              setContext({
                errors: { action: `Action.Error.${ProjectErrors.Failed}` },
              })

              break
            default:
              setContext({
                errors: { action: `Action.Error.Failed` },
              })
          }
        },
      })
    } catch (err) {
      setContext({ errors: { action: 'Action.Error.Failed' } })
    }
  }, [])

  if (context?.errors) {
    return (
      <Layout.Container>
        <Layout.Body>
          <Containers.ActionError error={context.errors.action} />
          <Containers.CloseButton onCancel={() => hide()} />
        </Layout.Body>
      </Layout.Container>
    )
  }

  return <Containers.ProgressBar />
}

/**
 */
const RefetchStep = ({ next: subNext, context }) => {
  const {
    refetch: projectsRefetch,
  } = Project.Remote.Queries.MyEntries.useContext()

  const {
    refetch: projectAuthorizationsRefetch,
  } = ProjectAuthorization.Remote.Queries.MyEntries.useContext()

  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (error) {
      window.location.href = Routes.Project.toSetting({
        projectId: context?.clonedEntry?.slug,
      })
    }
  }, [error])

  useAsync(async () => {
    try {
      await projectsRefetch()
      await projectAuthorizationsRefetch()

      subNext()
    } catch (error) {
      setError(error)
    }
  }, [])

  return <Containers.ProgressBar />
}

/**
 */
const ProgressSubFlow = createFlow([ProgressStep, RefetchStep])

/**
 */
export const Progress = ({ project, next, prev, context }) => {
  return (
    <Layout.Container>
      <Layout.Body>
        <ProgressSubFlow
          project={project}
          context={context}
          onComplete={(context) => {
            next({ clonedEntry: context?.clonedEntry })
          }}
          prev={prev}
        />
      </Layout.Body>
    </Layout.Container>
  )
}

/**
 */
export const Complete = ({ context, onComplete, ...rem }) => {
  React.useEffect(() => {
    onComplete(context)
  }, [])

  return null
}
