import React from 'react'

import * as ProjectRoutes from '@arch-log/webapp.shared/AppDefinitions/Project/Routes'
import * as ConfirmModal from '@arch-log/webapp.shared/ConfirmModal'
import * as ConfirmModalContent from '@arch-log/webapp.shared/ConfirmModal/Content'
import * as ProjectExtention from '@arch-log/webapp.modules/project-extention.v2/components'
import * as ProjectExtentionConsts from '@arch-log/webapp.modules/project-extention.v2/consts'
import * as Containers from './Containers'
import * as Layout from './Layout'
import * as Styled from './Styled'
import * as Literals from './Literals'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'
import { useAsync } from 'react-use'

/**
 */
export const Init = ({ next }) => {
  React.useEffect(() => {
    next({})
  }, [])

  return null
}

/**
 */
export const Confirm = ({ next, context }) => {
  const { hideConfirm } = ConfirmModal.useConfirmModal()

  return (
    <ConfirmModalContent.Default
      onOk={(e) => {
        e.hideModal = false
        next()
      }}
      onCancel={() => {
        hideConfirm()
      }}
      cancel={() => <Literals.Cancel />}
      components={{
        Message: () => (
          <>
            <Containers.ConfirmMessage />
            <Containers.ActionErrorMessage
              errorMessage={context?.errors?.action}
            />
          </>
        ),
        Ok: {
          Button: Styled.AcceptButton,
        },
        Layout: {
          Wrapper: Layout.ConfirmLayoutWrapper,
          Body: Layout.ConfirmLayoutBody,
        },
      }}
    />
  )
}

/**
 */
const ProgressStep = ({ next, prev, projectUUId }) => {
  const [
    deactivateEntry,
  ] = ProjectExtention.Remote.Mutations.DeactivateEntry.useMutation()

  useAsync(async () => {
    try {
      await deactivateEntry({
        variables: {
          projectId: projectUUId,
          name: ProjectExtentionConsts.ProjectExtension_Name,
        },
        onCompleted: (data) => {
          next()
        },
        onError: (error) => {
          prev({
            errors: { action: `Action.Error.Failed` },
          })
        },
      })
    } catch (err) {
      prev({ errors: { action: 'Action.Error.Failed' } })
    }
  }, [])

  return <Containers.ProgressBar />
}

/**
 */
const RefetchStep = ({ next, projectId, onRefetch }) => {
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (error) {
      window.location.href = ProjectRoutes.toSetting({ project: projectId })
    }
  }, [error])

  useAsync(async () => {
    try {
      await onRefetch()
      next()
    } catch (error) {
      setError(error)
    }
  }, [])

  return <Containers.ProgressBar />
}

/**
 */
const BaseSubFlow = createFlow([ProgressStep, RefetchStep])

/**
 */
export const Progress = ({ projectId, projectUUId, next, prev, onRefetch }) => {
  return (
    <Layout.Wrapper>
      <Layout.Body>
        <BaseSubFlow
          projectId={projectId}
          projectUUId={projectUUId}
          onRefetch={onRefetch}
          prev={prev}
          onComplete={() => {
            next()
          }}
        />
      </Layout.Body>
    </Layout.Wrapper>
  )
}

/**
 */
export const Complete = ({ onComplete }) => {
  React.useEffect(() => {
    onComplete()
  }, [])

  return null
}
