import React from 'react'

import * as ProjectRoutes from '@arch-log/webapp.shared/AppDefinitions/Project/Routes'
import * as ConfirmModal from '@arch-log/webapp.shared/ConfirmModal'
import * as ConfirmModalContent from '@arch-log/webapp.shared/ConfirmModal/Content'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as ProjectListEntry from '@arch-log/webapp.shared/Project.v2/List/Entry'
import * as DeleteForm from 'modules/project/components/DeleteForm'
import * as Fields from './Fields'
import * as Containers from './Containers'
import * as Layout from './Layout'
import * as Styled from './Styled'
import * as Literals from './Literals'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'
import { useAsync } from 'react-use'

/**
 */
export const Init = ({ next }) => {
  React.useEffect(() => {
    next({
      defaultValues: { password: '' },
    })
  }, [])

  return null
}

/**
 */
export const Input = ({ project, next, context, onCancel }) => {
  return (
    <DeleteForm.Initializer
      onSubmit={(data) => next({ formData: data })}
      defaultValues={context?.defaultValues}
      values={context?.formData}
      errors={context?.errors}
    >
      <Layout.Container>
        <Layout.Body>
          <Layout.BodySection>
            <Project.Remote.Queries.EntryByUuid.Loader
              id={project.uuid}
              components={{ Loading: () => <>Loading</> }}
            >
              <ProjectListEntry.Default />
            </Project.Remote.Queries.EntryByUuid.Loader>
          </Layout.BodySection>
          <Containers.Description />
          <Containers.FormErros />
          <Fields.Password />
        </Layout.Body>
        <Layout.Footer>
          <DeleteForm.Submit render={Containers.SubmitButton} />
          <Containers.CancelButton onCancel={onCancel} />
        </Layout.Footer>
      </Layout.Container>
    </DeleteForm.Initializer>
  )
}

/**
 */
export const Confirm = ({ next }) => {
  const { hideConfirm } = ConfirmModal.useConfirmModal()

  return (
    <ConfirmModalContent.Default
      onOk={(e) => {
        e.hideModal = false
        next()
      }}
      onCancel={() => {
        hideConfirm()
      }}
      cancel={() => <Literals.Cancel />}
      components={{
        Message: () => <Containers.ConfirmMessage />,
        Ok: {
          Button: Styled.AcceptButton,
        },
        Layout: {
          Wrapper: Layout.ConfirmLayoutWrapper,
        },
      }}
    />
  )
}

/**
 */
const ProgressStep = ({ next, prev, project, context }) => {
  const [deleteEntry] = Project.Remote.Mutations.DeleteEntry.useMutation()

  useAsync(async () => {
    try {
      await deleteEntry({
        variables: {
          //id: project.uuid.toString(),
          password: context?.formData?.password,
        },
        onCompleted: (data) => {
          next()
        },
        onError: (error) => {
          prev({
            errors: { _form: `Form.Error.${error.message}` },
          })
        },
      })
    } catch (err) {
      prev({ errors: { _form: 'Form.Error.ContactToOpeartor' } })
    }
  }, [])

  return <Containers.ProgressBar />
}

/**
 */
const RefetchStep = ({ next }) => {
  const { refetch } = Project.Remote.Queries.MyEntries.useContext()

  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (error) {
      window.location.href = ProjectRoutes.toProjectList()
    }
  }, [error])

  useAsync(async () => {
    try {
      await refetch()
      next()
    } catch (error) {
      setError(error)
    }
  }, [])

  return <Containers.ProgressBar />
}

/**
 */
const BaseSubFlow = createFlow([ProgressStep, RefetchStep])

/**
 */
export const Progress = ({ project, next, prev, context }) => {
  return (
    <Layout.Container>
      <Layout.Body>
        <BaseSubFlow
          project={project}
          context={context}
          onComplete={() => {
            next()
          }}
          prev={prev}
        />
      </Layout.Body>
    </Layout.Container>
  )
}

/**
 */
export const Complete = ({ onComplete }) => {
  React.useEffect(() => {
    onComplete()
  }, [])

  return null
}
