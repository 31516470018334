import React from 'react'
import moment from 'moment'

import { Context as StyleContext } from './Style'

/**
 */
export const Hidden = ({
  className,
  placeholder,
  formatPlaceholder = (t) => t,
  autocomplete = 'off',
  disabled,
  ...props
}) => {
  const { styles } = React.useContext(StyleContext)
  return (
    <input
      type="hidden"
      className={className ?? styles.Hidden}
      placeholder={formatPlaceholder(placeholder)}
      autoComplete={autocomplete}
      disabled={disabled}
      {...props}
    />
  )
}

/**
 */
export const Text = ({
  className,
  placeholder,
  formatPlaceholder = (t) => t,
  autocomplete = 'off',
  disabled,
  ...props
}) => {
  const { styles } = React.useContext(StyleContext)
  return (
    <input
      type="text"
      className={className ?? styles.Text}
      placeholder={formatPlaceholder(placeholder)}
      autoComplete={autocomplete}
      disabled={disabled}
      {...props}
    />
  )
}

/**
 */
export const TextArea = ({
  className,
  placeholder,
  formatPlaceholder = (t) => t,
  cols,
  rows,
  disabled,
  maxLength,
  ...props
}) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <textarea
      className={className ?? styles.Text}
      placeholder={formatPlaceholder(placeholder)}
      cols={cols}
      rows={rows}
      disabled={disabled}
      maxLength={maxLength}
      {...props}
    />
  )
}

/**
 */
export const Number = ({
  className,
  placeholder,
  formatPlaceholder = (t) => t,
  disabled,
  ...props
}) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <input
      type="number"
      className={className ? className : styles.Input_Number}
      placeholder={formatPlaceholder(placeholder)}
      disabled={disabled}
      {...props}
    />
  )
}

/**
 */
export const Email = ({
  className,
  placeholder,
  formatPlaceholder = (t) => t,
  autocomplete = 'off',
  disabled,
  ...props
}) => {
  const { styles } = React.useContext(StyleContext)
  return (
    <input
      type="email"
      className={className}
      placeholder={formatPlaceholder(placeholder)}
      disabled={disabled}
      autoComplete={autocomplete}
      {...props}
    />
  )
}

/**
 */
export const Radio = ({
  className,
  disabled,
  checked,
  value,
  children,
  ...props
}) => {
  const { styles } = React.useContext(StyleContext)
  return (
    <label>
      <input
        type="radio"
        className={className ?? styles.CheckBox}
        disabled={disabled}
        checked={checked}
        value={value}
        {...props}
      />
      {children}
    </label>
  )
}

/**
 */
export const CheckBox = React.forwardRef(
  (
    //
    { className, checked, disabled, value, id, children, ...props },
    ref,
  ) => {
    const { styles } = React.useContext(StyleContext)

    return (
      <label htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          value={value}
          className={className ?? styles.CheckBox}
          disabled={disabled}
          checked={checked}
          ref={ref}
          {...props}
        />
        {children}
      </label>
    )
  },
)

/**
 */
export const Option = ({ className, value, label, disabled }) => {
  const { styles } = React.useContext(StyleContext)
  return (
    <option
      value={value}
      className={className ?? styles.Option}
      disabled={disabled}
    >
      {label}
    </option>
  )
}

/**
 */
export const SelectBox = ({
  className,
  options,
  input,
  formatOption = (t) => t,
  renderOption: OptionRenderer = Option,
  defaultOption: DefaultOptionRenderer = Option,
  disabled,
  ...props
}) => {
  const { styles } = React.useContext(StyleContext)

  const optionNodes = options.map(({ value, label }) => (
    <OptionRenderer
      key={value}
      value={value}
      label={formatOption(`${label}`)}
    />
  ))

  return (
    <select
      className={className ?? styles.SelectBox}
      disabled={disabled}
      {...input}
      {...props}
    >
      <DefaultOptionRenderer
        value={''}
        label={formatOption('NotSelected')}
        disabled={'disabled'}
      />
      {optionNodes}
    </select>
  )
}

/**
 */
export const Password = React.forwardRef(
  (
    {
      className,
      placeholder,
      disabled,
      formatPlaceholder = (t) => t,
      autoComplete = 'off',
      ...props
    },
    ref,
  ) => {
    const { styles } = React.useContext(StyleContext)
    return (
      <input
        type="password"
        className={className ?? styles.Password}
        placeholder={formatPlaceholder(placeholder)}
        disabled={disabled}
        autoComplete={autoComplete}
        ref={ref}
        {...props}
      />
    )
  },
)

const formatChoicesOptions = (options) => {
  if (typeof options === 'object' && !Array.isArray(options)) {
    return Object.entries(options).map(([key, value]) => ({
      value: key,
      label: value,
    }))
  } else {
    return options
  }
}

const Fragment = ({ children }) => <>{children}</>

/**
 *
 */
export const MultiChoices = ({
  options,
  components: { Container = Fragment, Item = Fragment } = {},
  value,
  disabled,
  ...props
}) => {
  const selectedValues = [value].flat()

  const formattedOptions = formatChoicesOptions(options)

  const items = options.map(({ value, label = null, options = {} }) => (
    <Item key={value}>
      <CheckBox
        checked={selectedValues.includes(value)}
        value={value}
        disabled={disabled}
        {...options}
        {...props}
      >
        {label ?? value}
      </CheckBox>
    </Item>
  ))

  return <Container>{items}</Container>
}

/**
 *
 */
export const Choices = ({
  disabled,
  options,
  value: selectedValue,
  components: { Container = Fragment, Item = Fragment } = {},
  ...props
}) => {
  const formattedOptions = formatChoicesOptions(options)

  const items = formattedOptions.map(
    ({ value, label = null, options = {} }) => {
      return (
        <Item key={value}>
          <Radio
            checked={value === selectedValue}
            value={value}
            disabled={disabled}
            {...options}
            {...props}
          >
            {label ?? value}
          </Radio>
        </Item>
      )
    },
  )

  return <Container>{items}</Container>
}

/**
 */
export const SelectYear = ({
  className,
  disabled,
  pivotAt = moment(),
  since = null,
  until = moment(pivotAt).add(10, 'years'),
  formatter = (v) => v,
  ...props
}) => {
  const startAt = moment.min([pivotAt, since].filter((v) => v))
  const endAt = moment.max([pivotAt, until].filter((v) => v))

  const years = new Array(endAt.year() - startAt.year() + 1)
    .fill()
    .map((_, index) => {
      return startAt.year() + index
    })

  const options = years.map((year) => ({
    value: year,
    label: formatter(year),
  }))

  return (
    <SelectBox
      className={className}
      options={options}
      disabled={disabled}
      {...props}
    />
  )
}

/**
 */
export const SelectMonth = ({
  pivotAt = moment(),
  since = null,
  until = null,
  formatter = (v) => v,
  className,
  disabled,
  ...props
}) => {
  const startAt = moment.max(
    [moment(pivotAt).startOf('year'), since].filter((v) => v),
  )
  const endAt = moment.min(
    [moment(pivotAt).endOf('year'), until].filter((v) => v),
  )

  if (startAt.isAfter(endAt)) {
    throw new Error('Invalid')
  }

  const months = new Array(endAt.month() - startAt.month() + 1)
    .fill()
    .map((_, index) => {
      return startAt.month() + index + 1
    })

  const options = months.map((month) => ({
    value: month,
    label: formatter(month),
  }))

  return (
    <SelectBox
      className={className}
      options={options}
      disabled={disabled}
      {...props}
    />
  )
}

/**
 */
export const SelectDay = ({
  pivotAt = moment(),
  since = null,
  until = null,
  formatter = (v) => v,
  className,
  disabled,
  ...props
}) => {
  const startAt = moment.max(
    [moment(pivotAt).startOf('month'), since].filter((v) => v),
  )
  const endAt = moment.min(
    [moment(pivotAt).endOf('month'), until].filter((v) => v),
  )

  if (startAt.isAfter(endAt)) {
    throw new Error('Invalid')
  }

  const days = new Array(endAt.date() - startAt.date() + 1)
    .fill()
    .map((_, index) => {
      return startAt.date() + index
    })

  const options = days.map((day) => ({
    value: day,
    label: formatter(day),
  }))

  return (
    <SelectBox
      className={className}
      options={options}
      disabled={disabled}
      {...props}
    />
  )
}

/**
 */
export const SelectHour = ({
  pivotAt = moment(),
  since = null,
  until = null,
  formatter = (v) => v,
  className,
  disabled,
  ...props
}) => {
  const startAt = moment.max(
    [moment(pivotAt).startOf('date'), since].filter((v) => v),
  )

  const endAt = moment.min(
    [moment(pivotAt).endOf('date'), until].filter((v) => v),
  )

  if (startAt.isAfter(endAt)) {
    throw new Error('Invalid')
  }

  const hours = new Array(endAt.hour() - startAt.hour() + 1)
    .fill()
    .map((_, index) => {
      return startAt.hour() + index
    })

  const options = hours.map((hour) => ({
    value: hour,
    label: formatter(hour),
  }))

  return (
    <SelectBox
      className={className}
      options={options}
      disabled={disabled}
      {...props}
    />
  )
}

/**
 */
export const SelectDuration = ({
  numOfDuration = 24,
  formatter = (v) => `${v}h`,
  className,
  disabled,
  ...props
}) => {
  const options = new Array(numOfDuration).fill().map((_, index) => ({
    value: index + 1,
    label: formatter(index + 1),
  }))

  return (
    <SelectBox
      className={className}
      options={options}
      disabled={disabled}
      {...props}
    />
  )
}
