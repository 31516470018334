import React from 'react'

import * as Literals from './Literals'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'

/**
 */
export const DeleteButton = ({ onDelete }) => (
  <Styled.Button className={styles.Button} onClick={() => onDelete()}>
    <Literals.Delete />
  </Styled.Button>
)

/**
 */
export const CloneButton = ({ onClone }) => (
  <Styled.Button className={styles.Button} onClick={() => onClone()}>
    <Literals.Clone />
  </Styled.Button>
)
