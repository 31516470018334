import React from 'react'

import * as CloneForm from 'modules/project/components/CloneForm'
import * as Literals from './Literals'

/**
 */
export const IncludeBoards = ({ ...props }) => (
  <CloneForm.Fields.IncludeBoards
    {...props}
  >
    <Literals.IncludeBoards />
  </CloneForm.Fields.IncludeBoards>
)

/**
 */
export const IncludeFts = ({ ...props }) => (
  <CloneForm.Fields.IncludeFts {...props}>
    <Literals.IncludeFts />
  </CloneForm.Fields.IncludeFts>
)
