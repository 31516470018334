import React from 'react'

import * as DeleteForm from 'modules/project/components/DeleteForm'
import * as Literals from './Literals'

import * as styles from './styles.module.scss'

export const Password = ({ formatMessage, ...props }) => {
  const { formatFieldError } = Literals.useLiterals()

  return (
    <>
      <DeleteForm.FieldErrors.Password formatMessage={formatFieldError} />
      <DeleteForm.Fields.Password className={styles.PasswordInput} {...props} />
    </>
  )
}
