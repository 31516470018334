import React from 'react'

import * as Project from '@arch-log/webapp.modules/project.v2/components'

import * as CloneModal from './CloneModal'
import * as DeleteModal from './DeleteModal'

/**
 */
export const Delete = ({ render: Renderer }) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  const { showModal: showModal } = DeleteModal.useActions()

  return (
    <Renderer
      onDelete={() => {
        showModal({
          project: projectEntry,
        })
      }}
    />
  )
}

/**
 */
export const Clone = ({ render: Renderer }) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  const { showModal } = CloneModal.useActions()

  return (
    <Renderer
      onClone={() => {
        showModal({
          project: projectEntry,
        })
      }}
    />
  )
}
