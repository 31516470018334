import React from 'react'

import { useFormContext } from 'react-hook-form'

import * as Inputs from '@arch-log/webapp.atomics/components/Form/Input/Renderers'
import * as FieldNames from './FieldNames'

/**
 */
export const IncludeBoards = ({
  children,
  render: Renderer = Inputs.CheckBox,
  ...props
}) => {
  const { register } = useFormContext()

  return (
    <Renderer {...register(FieldNames.IncludeBoards)} {...props}>
      {children}
    </Renderer>
  )
}

/**
 */
export const IncludeFts = ({
  children,
  render: Renderer = Inputs.CheckBox,
  ...props
}) => {
  const { register } = useFormContext()

  return (
    <Renderer {...register(FieldNames.IncludeFts)} {...props}>
      {children}
    </Renderer>
  )
}
