import React from 'react'

import * as Steps from './Steps'
import * as Project from '@arch-log/webapp.modules/project.v2/components'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'

/**
 */
const BaseFlow = createFlow([
  Steps.Init,
  Steps.Input,
  Steps.Progress,
  Steps.Complete,
])

/**
 */
export const Flow = (props) => {
  const { project } = props
  return (
    <Project.Remote.Mutations.CloneEntry.Initializer sourceId={project.uuid}>
      <BaseFlow {...props} />
    </Project.Remote.Mutations.CloneEntry.Initializer>
  )
}
