import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('app.project')('ProjectInfo.Title')

/**
 */
export const Edit = translated('app.project')('ProjectInfo.Edit')

/**
 */
export const GroundFloorsPrefix = translated('module.project')(
  'Field.Formats.GroundFloors.Prefix',
)

/**
 */
export const GroundFloorsSuffix = translated('module.project')(
  'Field.Formats.GroundFloors.Suffix',
)

/**
 */
export const BasementFloorsPrefix = translated('module.project')(
  'Field.Formats.BasementFloors.Prefix',
)

/**
 */
export const BasementFloorsSuffix = translated('module.project')(
  'Field.Formats.BasementFloors.Suffix',
)

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation(['module.address', 'module.project'])

  const formatFieldLabel = (field, ...options) =>
    t(
      [
        `module.address:Field.Labels.${field}`,
        `module.project:Field.Labels.${field}`,
      ],
      ...options,
    )

  const formatPropertyArea = (key, ...options) =>
    t(`module.project:PropertyArea.${key}`, ...options)

  const formatPropertyTypes = (key, ...options) =>
    t(`module.project:PropertyTypes.${key}`, ...options)

  const formatConstructionTypes = (key, ...options) =>
    t(`module.project:ConstructionTypes.${key}`, ...options)

  return {
    formatFieldLabel,
    formatPropertyArea,
    formatPropertyTypes,
    formatConstructionTypes,
  }
}
