import React from 'react'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'

import * as ProjectExtention from '@arch-log/webapp.modules/project-extention.v2/components'
import * as Steps from './Steps'

/**
 */
const BaseFlow = createFlow([
  Steps.Init,
  Steps.Confirm,
  Steps.Progress,
  Steps.Complete,
])

/**
 */
export const Flow = ({ projectUUId, ...props }) => {
  return (
    <ProjectExtention.Remote.Mutations.TrialEntry.Initializer>
      <BaseFlow projectUUId={projectUUId} {...props} />
    </ProjectExtention.Remote.Mutations.TrialEntry.Initializer>
  )
}
