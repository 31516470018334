import React from 'react'

import { Loader } from '@arch-log/webapp.atomics/components/Loader'

import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const ProgressBar = () => {
  return (
    <Loader>
      <Literals.Progress />
    </Loader>
  )
}
/**
 */
export const ActionErrorMessage = ({ errorMessage }) => {
  const { formatActionError } = Literals.useLiterals()

  if (!errorMessage) {
    return null
  }

  return (
    <Styled.ActionErrorMessage>
      {formatActionError(errorMessage)}
    </Styled.ActionErrorMessage>
  )
}

/**
 */
export const ConfirmMessage = () => (
  <Styled.ConfirmMessage>
    <Literals.ConfirmMessage />
  </Styled.ConfirmMessage>
)

/**
 */
export const CancelButton = ({ onCancel }) => (
  <Styled.CancelButton onClick={onCancel}>
    <Literals.Cancel />
  </Styled.CancelButton>
)

/**
 */
export const AcceptButton = ({ onAccept, ...props }) => (
  <Styled.AcceptButton onAccept={onAccept} {...props}>
    <Literals.Accept />
  </Styled.AcceptButton>
)
