// extracted by mini-css-extract-plugin
export var Body = "layout-module--Body--FtnAB";
export var FieldContainer = "layout-module--FieldContainer--VptPx";
export var FieldGropContainer = "layout-module--FieldGropContainer--1BSxC";
export var FieldGroupLabel = "layout-module--FieldGroupLabel--ouSSg";
export var FieldGroupValue = "layout-module--FieldGroupValue--+eeKG";
export var FieldLabel = "layout-module--FieldLabel--XNni5";
export var FieldValue = "layout-module--FieldValue--7f2J-";
export var Footer = "layout-module--Footer--SRvEU";
export var Header = "layout-module--Header--brqXE";
export var Wrapper = "layout-module--Wrapper--hroA8";