// extracted by mini-css-extract-plugin
export var Container = "styles-module--Container--Rsmx7";
export var DetailsSection = "styles-module--DetailsSection--grC+R";
export var Entry_Link_Arrow = "styles-module--Entry_Link_Arrow--Uxsim";
export var Field = "styles-module--Field--k-EZ7";
export var FieldLabel = "styles-module--FieldLabel--A-F8f";
export var FieldValue = "styles-module--FieldValue--1skv3";
export var Fields = "styles-module--Fields--VswFV";
export var Icon_Link = "styles-module--Icon_Link--c-ezq";
export var Label = "styles-module--Label---3VjG";
export var Link = "styles-module--Link--W83Np";
export var Row = "styles-module--Row--Zp-XR";