import React from 'react'

import { Loader } from '@arch-log/webapp.atomics/components/Loader'

import * as DeleteForm from 'modules/project/components/DeleteForm'
import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const Description = () => (
  <Styled.Description>
    <Literals.Description />
  </Styled.Description>
)

/**
 */
export const CancelButton = ({ onCancel }) => (
  <Styled.CancelButton onClick={onCancel}>
    <Literals.Cancel />
  </Styled.CancelButton>
)

/**
 */
export const SubmitButton = () => (
  <Styled.SubmitButton>
    <Literals.Submit />
  </Styled.SubmitButton>
)

/**
 */
export const FormErros = () => {
  const { formatFormError } = Literals.useLiterals()

  return <DeleteForm.FormError formatMessage={formatFormError} />
}

/**
 */
export const ProgressBar = () => {
  return (
    <Loader>
      <Literals.Progress />
    </Loader>
  )
}

/**
 */
export const ConfirmMessage = ({
  componenst: { Decorator = Styled.Message } = {},
}) => (
  <Decorator>
    <Literals.ConfirmMessage />
  </Decorator>
)

/**
 */
export const AcceptButton = ({
  components: { Button = Styled.AcceptButton } = {},
  onClick,
  ...props
}) => (
  <Button onClick={onClick} {...props}>
    <Literals.DeleteConfirmAccept />
  </Button>
)
