import React from 'react'

import { Loader } from '@arch-log/webapp.atomics/components/Loader'

import * as CloneForm from 'modules/project/components/CloneForm'
import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const ActionError = ({ error }) => {
  const { formatError } = Literals.useLiterals()

  return <Styled.ActionError>{formatError(error)}</Styled.ActionError>
}

/**
 */
export const Description = () => (
  <Styled.Description>
    <Literals.Description />
  </Styled.Description>
)


/**
 */
export const NoticeIncludeImages = () => (
  <Styled.NoticeWrapper>
    <Literals.NoticeIncludeImages/>
  </Styled.NoticeWrapper>
)

/**
 */
export const CloseButton = ({ onCancel }) => (
  <Styled.CloseButton onClick={onCancel}>
    <Literals.Close />
  </Styled.CloseButton>
)

/**
 */
export const CancelButton = ({ onCancel }) => (
  <Styled.CancelButton onClick={onCancel}>
    <Literals.Cancel />
  </Styled.CancelButton>
)

/**
 */
export const SubmitButton = () => (
  <Styled.SubmitButton>
    <Literals.Submit />
  </Styled.SubmitButton>
)

/**
 */
export const FormErros = () => {
  const { formatFormError } = Literals.useLiterals()

  return <CloneForm.FormError formatMessage={formatFormError} />
}

/**
 */
export const ProgressBar = () => {
  return (
    <Loader>
      <Literals.Progress />
    </Loader>
  )
}
