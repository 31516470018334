import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Button = ({ children, onClick }) => (
  <button className={styles.Button} onClick={onClick}>
    {children}
  </button>
)
