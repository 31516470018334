import React from 'react'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Layout from './Layout'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
const createFieldGroup = (fieldName, FieldRenderer) => ({
  components: {
    Container = Layout.FieldGroupContainer,
    Label = Layout.FieldGroupLabel,
    Value = Layout.FieldGroupValue,
  } = {},
  ...props
}) => {
  const { formatFieldLabel } = Literals.useLiterals()

  return (
    <Container>
      <Label>{formatFieldLabel(fieldName)}</Label>
      <Value>
        <FieldRenderer {...props} />
      </Value>
    </Container>
  )
}

/**
 */
const createField = (fieldName, FieldRenderer) => ({
  components: {
    Container = Layout.FieldContainer,
    Label = Layout.FieldLabel,
    Value = Layout.FieldValue,
  } = {},
  ...props
}) => {
  const { formatFieldLabel } = Literals.useLiterals()

  return (
    <Container>
      <Label>{formatFieldLabel(fieldName)}</Label>
      <Value>
        <FieldRenderer {...props} />
      </Value>
    </Container>
  )
}

/**
 */
export const ProjectName = createField('ProjectName', () => (
  <Project.Scope.Entry.Properties.Label />
))

/**
 */
export const PropertyType = createField('PropertyType', () => {
  const { formatPropertyTypes } = Literals.useLiterals()

  return (
    <Project.Scope.Entry.Properties.PropertyInfo.PropertyType
      formatter={formatPropertyTypes}
    />
  )
})

/**
 */
export const Address = createFieldGroup('Address', () => (
  <>
    <AddressPostalCode />
    <AddressStateOrPrefecture />
    <AddressCity />
    <AddressStreet />
    <AddressBuilding />
  </>
))

/**
 */
export const AddressPostalCode = createField('PostalCode', () => (
  <Project.Scope.Entry.Properties.Address.PostalCode />
))

/**
 */
export const AddressStateOrPrefecture = createField('StateOrPrefecture', () => (
  <Project.Scope.Entry.Properties.Address.StateOrPrefecture />
))

/**
 */
export const AddressCity = createField('City', () => (
  <Project.Scope.Entry.Properties.Address.City />
))

/**
 */
export const AddressStreet = createField('Street', () => (
  <Project.Scope.Entry.Properties.Address.Street />
))

/**
 */
export const AddressBuilding = createField('Building', () => (
  <Project.Scope.Entry.Properties.Address.Building />
))

/**
 */
export const PropertyArea = createField('PropertyArea', () => {
  const { formatPropertyArea } = Literals.useLiterals()

  return (
    <Project.Scope.Entry.Properties.PropertyInfo.Area
      formatter={formatPropertyArea}
    />
  )
})

/**
 */
export const Floors = createField('Floors', () => (
  <>
    <BasementFloors />
    <GroundFloors />
  </>
))

/**
 */
const GroundFloors = () => {
  return (
    <>
      <Project.Scope.Entry.Properties.PropertyInfo.GroundFloors
        prefix={<Literals.GroundFloorsPrefix />}
        suffix={<Literals.GroundFloorsSuffix />}
        className={styles.Floor_Groud}
      />
    </>
  )
}

/**
 */
const BasementFloors = () => {
  return (
    <>
      <Project.Scope.Entry.Properties.PropertyInfo.BasementFloors
        prefix={<Literals.BasementFloorsPrefix />}
        suffix={<Literals.BasementFloorsSuffix />}
        className={styles.Floor_Basement}
      />
    </>
  )
}

/**
 */
export const ConstructionType = createField('ConstructionType', () => {
  const { formatConstructionTypes } = Literals.useLiterals()

  return (
    <Project.Scope.Entry.Properties.ConstructionInfo.ConstructionType
      formatter={formatConstructionTypes}
    />
  )
})

/**
 */
export const ConstructionStartMonth = createField(
  'ConstructionStartMonth',
  () => <Project.Scope.Entry.Properties.ConstructionInfo.StartMonth />,
)

/**
 */
export const ConstructionEndMonth = createField('ConstructionEndDate', () => (
  <Project.Scope.Entry.Properties.ConstructionInfo.EndMonth />
))
