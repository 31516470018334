import React from 'react'

/**
 */
export const Context = React.createContext({
  visibilities: {
    constructionMonths: true,
    propertyArea: true,
    constructionType: true,
    propertyType: true,
    address: true,
    floors: true,
    createdAt: false,
    updatedAt: false,
  },
  setVisibilities: () => {
    throw new Error('Context is not initialized yet')
  },
})
