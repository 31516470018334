import React from 'react'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as ProjectLinks from '@arch-log/webapp.shared/Project.v2/Links'
import * as Fields from './Fields'
import * as Styled from './Styled'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const Title = () => {
  return (
    <Styled.Title>
      <Literals.Title />
    </Styled.Title>
  )
}

/**
 */
export const ProjectEdit = () => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()

  if (!hasPermission(Permissions.Edit)) {
    return null
  }

  return (
    <ProjectLinks.ToEditInfo className={styles.EditButton}>
      <Literals.Edit />
    </ProjectLinks.ToEditInfo>
  )
}

/**
 */
export const FieldProperties = () => {
  return (
    <>
      <Fields.ProjectName />
      <Fields.PropertyType />
      <Fields.Address />
      <Fields.PropertyArea />
      <Fields.Floors />
      <Fields.ConstructionType />
      <Fields.ConstructionStartMonth />
      <Fields.ConstructionEndMonth />
    </>
  )
}
