import {
  Validators as Requirements,
  Invalidations,
} from '@arch-log/webapp.lib/form/validation'

import * as FieldInvalidations from './Invalidations'

/**
 */
export const validatePassword = (value) => {
  try {
    Requirements.Required(value)
    // Requirements.Pattern.PasswordLength(value)
    // Requirements.Pattern.IncludeAlphabetLowerCase(value)
    // Requirements.Pattern.IncludeAlphabetUpperCase(value)
    // Requirements.Pattern.IncludeNumeral(value)
    // Requirements.Pattern.IncludeSymbol(value)
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return FieldInvalidations.Missing
      // case Invalidations.InvalidLength:
      //   return FieldInvalidations.InvalidPasswordLength
      // case Invalidations.MissingLowerCase:
      //   return FieldInvalidations.MissingPasswordLowerCase
      // case Invalidations.MissingUpperCase:
      //   return FieldInvalidations.MissingPasswordUpperCase
      // case Invalidations.MissingNumeral:
      //   return FieldInvalidations.MissingPasswordNumeral
      // case Invalidations.MissingSymbol:
      //   return FieldInvalidations.MissingPasswordSymbol
      default:
        return
    }
  }
}
