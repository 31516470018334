import React from 'react'

import * as ProjectInfo from './ProjectInfo'
import * as LockSystemRequest from './LockSystemRequest'
import * as Actions from './Actions'
import * as Containers from './Containers'
import * as Layout from './Layout'
import * as Literals from './Literals'

/**
 */
export const DeleteSectionDescription = ({
  components: { Layout: { Description = Layout.Description } = {} } = {},
}) => (
  <Description>
    <Literals.DeleteDescription />
  </Description>
)

/**
 */
export const CloneSectionDescription = ({
  components: { Layout: { Description = Layout.Description } = {} } = {},
}) => (
  <Description>
    <Literals.CloneDescription />
  </Description>
)

/**
 */
export const ProjectInfoSection = ({
  components: { ProjectInfo: projectInfoComponents } = {},
}) => <ProjectInfo.Default components={projectInfoComponents} />

/**
 */
export const CloneSection = ({
  components: {
    Layout: { Container = Layout.Container } = {},
    Description = CloneSectionDescription,
    Button = Containers.CloneButton,
  } = {},
}) => {
  return (
    <Container>
      <Description />
      <Actions.Clone render={Button} />
    </Container>
  )
}

/**
 */
export const DeleteSection = ({
  components: {
    Layout: { Container = Layout.Container } = {},
    Description = DeleteSectionDescription,
    Button = Containers.DeleteButton,
  } = {},
}) => {
  return (
    <Container>
      <Description />
      <Actions.Delete render={Button} />
    </Container>
  )
}

/**
 */
export const LockSystemRequestSection = ({
  components: { Layout: { Container = Layout.Container } = {} } = {},
}) => {
  return (
    <Container>
      <LockSystemRequest.Default />
    </Container>
  )
}
