import React from 'react'
import moment from 'moment'

import * as ProjectExtention from '@arch-log/webapp.modules/project-extention.v2/components'
import * as ProjectExtentionStates from '@arch-log/webapp.modules/project-extention.v2/states'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as DeactivateModal from './DeactivateModal'
import * as TrialModal from './TrialModal'
import * as Styled from './Styled'
import * as Literals from './Literals'

/**
 */
export const RequestTitle = () => {
  return <Literals.RequestTitle />
}

/**
 */
export const PropertyDatabaseFeatureTitle = () => {
  return <Literals.PropertyDatabaseFeatureTitle />
}

/**
 */
export const ProjectLockFeatureTitle = () => {
  return <Literals.ProjectLockFeatureTitle />
}

/**
 */
export const MaintenanceFeatureTitle = () => {
  return <Literals.MaintenanceFeatureTitle />
}

/**
 */
export const Deactive = () => {
  const [projectEntry] = Project.Scope.Entry.useEntry()
  const { formatRequestLabel } = Literals.useLiterals()
  const { showModal: showTrialModal } = TrialModal.useActions()

  return (
    <Styled.DeactiveButton
      onClick={() => {
        showTrialModal({
          projectId: projectEntry?.id.toString(),
          projectUUId: projectEntry?.uuid,
        })
      }}
    >
      {formatRequestLabel(ProjectExtentionStates.DEACTIVE)}
    </Styled.DeactiveButton>
  )
}

/**
 */
export const Trial = () => {
  const [projectEntry] = Project.Scope.Entry.useEntry()
  const { formatRequestLabel } = Literals.useLiterals()
  const { showModal: showDeactivateModal } = DeactivateModal.useActions()

  return (
    <Styled.TrialButton
      onClick={() => {
        showDeactivateModal({
          projectId: projectEntry?.id.toString(),
          projectUUId: projectEntry?.uuid,
        })
      }}
    >
      {formatRequestLabel(ProjectExtentionStates.TRIAL)}
    </Styled.TrialButton>
  )
}

/**
 */
export const Active = () => {
  const { formatRequestLabel } = Literals.useLiterals()

  return (
    <Styled.ActiveButton>
      {formatRequestLabel(ProjectExtentionStates.ACTIVE)}
    </Styled.ActiveButton>
  )
}

/**
 */
export const StatefulAction = () => {
  const [entry] = ProjectExtention.Scope.Entry.useEntry()

  switch (entry?.state) {
    case ProjectExtentionStates.DEACTIVE:
      return <Deactive />
    case ProjectExtentionStates.TRIAL:
      return <Trial />
    default:
      return <Active />
  }
}

/**
 */
export const RequestPrice = () => {
  return (
    <Styled.RequestPrice>
      <Literals.RequestPrice options={{ price: 500 }} />
    </Styled.RequestPrice>
  )
}

/**
 */
export const RequestedAt = ({ components: { Empty = () => <></> } = {} }) => {
  const [entry] = ProjectExtention.Scope.Entry.useEntry()

  if (!entry?.requestedAt) {
    return <Empty />
  }

  return (
    <Styled.RequestedAt>
      <Literals.RequestedAt
        options={{
          requestedAt: moment(entry?.requestedAt).format('YYYY/MM/DD'),
        }}
      />
    </Styled.RequestedAt>
  )
}
