import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'
import * as Descriptions from './Descriptions'

/**
 */
export const Default = () => {
  return (
    <Layout.Wrapper>
      <Layout.Header>
        <Layout.Title>
          <Containers.RequestTitle />
        </Layout.Title>
        <Layout.SectionDescription>
          <Descriptions.Request />
        </Layout.SectionDescription>
      </Layout.Header>

      <Layout.Body>
        <Layout.Section>
          <Layout.SectionTitle>
            <Containers.PropertyDatabaseFeatureTitle />
          </Layout.SectionTitle>
          <Layout.SectionDescription>
            <Descriptions.PropertyDatabaseFeature />
          </Layout.SectionDescription>
        </Layout.Section>

        <Layout.Section>
          <Layout.SectionTitle>
            <Containers.ProjectLockFeatureTitle />
          </Layout.SectionTitle>
          <Layout.SectionDescription>
            <Descriptions.ProjectLockFeature />
          </Layout.SectionDescription>
        </Layout.Section>

        <Layout.Section>
          <Layout.SectionTitle>
            <Containers.MaintenanceFeatureTitle />
          </Layout.SectionTitle>
          <Layout.SectionDescription>
            <Descriptions.MaintenanceFeature />
          </Layout.SectionDescription>
        </Layout.Section>
      </Layout.Body>

      <Layout.Footer>
        <Containers.RequestPrice />
        <Containers.StatefulAction />
        <Containers.RequestedAt />
      </Layout.Footer>
    </Layout.Wrapper>
  )
}
