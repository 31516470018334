import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Close = translated('setting')('Setting.Clone.Modal.Close')

/**
 */
export const Cancel = translated('setting')('Setting.Clone.Modal.Cancel')

/**
 */
export const Submit = translated('setting')('Setting.Clone.Modal.Submit')

/**
 */
export const Description = translated('setting')(
  'Setting.Clone.Modal.Description',
)

/**
 */
export const IncludeBoards = translated('setting')(
  'Setting.Clone.IncludeBoards',
)

/**
 */
export const IncludeFts = translated('setting')('Setting.Clone.IncludeFts')

/**
 */
export const Progress = translated('setting')('Setting.Clone.Progress')

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('setting')

  const formatError = (key, ...options) => t(`Setting.Clone.${key}`, ...options)

  const formatFormError = (key, ...options) =>
    t(`Setting.Clone.${key}`, ...options)

  return {
    formatError,
    formatFormError,
  }
}

export const NoticeIncludeImages = translated('setting')('Setting.Clone.NoticeIncludeImages')
