import React from 'react'

import * as Base from '@arch-log/webapp.atomics/components/Form/Input/Renderers'

export const Hidden = Base.Hidden
export const Text = Base.Text
export const TextArea = Base.TextArea
export const Number = Base.Number
export const Email = Base.Email
export const Radio = Base.Radio
export const Option = Base.Option
export const SelectBox = Base.SelectBox
export const MultiChoices = Base.MultiChoices
export const Password = Base.Password
export const Choices = Base.Choices
export const SelectYear = Base.SelectYear
export const SelectMonth = Base.SelectMonth
export const SelectDay = Base.SelectDay
export const SelectHour = Base.SelectHour
export const SelectDuration = Base.SelectDuration

export const CheckBox = ({ value, ...props }) => {
  return (
    <Base.CheckBox
      checked={value === 1 || value === true}
      {...props}
    ></Base.CheckBox>
  )
}
