import React from 'react'

import * as Routes from '@arch-log/webapp.shared/Routes'
import * as Modal from '@arch-log/webapp.atomics/components/Modal'
import * as Content from './Content'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'

/**
 */
export const useActions = () => {
  const { redirectTo } = useNavigate()
  const { show, hide } = React.useContext(Modal.Context)

  const showModal = ({ project }) => {
    show(() => (
      <Content.Flow
        project={project}
        onComplete={({ clonedEntry, ...rem}) => {
          redirectTo(Routes.Project.toSetting({ project: clonedEntry?.slug }))

          hide()
        }}
        onCancel={() => hide()}
      />
    ))
  }

  return {
    showModal,
    closeModal: hide,
  }
}
