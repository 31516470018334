import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const TrialButton = ({ onClick, children, ...props }) => {
  return (
    <button
      type="button"
      className={styles.TrialButton}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}

/**
 */
export const DeactiveButton = ({ onClick, children, ...props }) => {
  return (
    <button
      type="button"
      className={styles.DeactiveButton}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}

/**
 */
export const ActiveButton = ({ children, ...props }) => {
  return (
    <button type="button" className={styles.ActiveButton} {...props}>
      {children}
    </button>
  )
}

/**
 */
export const RequestPrice = ({ children }) => {
  return <p className={styles.RequestPrice}>{children}</p>
}

/**
 */
export const RequestedAt = ({ children }) => {
  return <p className={styles.RequestedAt}>{children}</p>
}
