import React from 'react'

import * as Scope from '../Scope'
import * as VisibilityOptions from '../VisibilityOptions'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 *
 */
const Field = ({ children }) => <div className={styles.Field}>{children}</div>

/**
 *
 */
const FieldLabel = ({ children }) => (
  <div className={styles.FieldLabel}>{children}</div>
)

/**
 */
const FieldValue = ({ children }) => (
  <div className={styles.FieldValue}>{children}</div>
)

/**
 */
export const Label = ({ ...props }) => {
  const { Entry: EntryScope } = Scope.useScope()
  return (
    <Field>
      <EntryScope.Properties.Label className={styles.Label} {...props} />
    </Field>
  )
}

/**
 */
export const Address = ({ ...props }) => {
  const { Entry: EntryScope } = Scope.useScope()

  return (
    <VisibilityOptions.IfVisible name="address">
      <Field>
        <EntryScope.Properties.Address.StateOrPrefecture {...props} />
        <EntryScope.Properties.Address.City {...props} />
        <EntryScope.Properties.Address.Street {...props} />
        <EntryScope.Properties.Address.Building {...props} />
      </Field>
    </VisibilityOptions.IfVisible>
  )
}

/**
 */
export const ConstructionMonth = ({ ...props }) => {
  const { Entry: EntryScope } = Scope.useScope()
  const { formatFieldLabel } = Literals.useLiterals()

  return (
    <VisibilityOptions.IfVisible name="constructionMonths">
      <Field>
        <FieldLabel>{formatFieldLabel('ConstructionMonths')}:</FieldLabel>
        <FieldValue>
          <EntryScope.Properties.ConstructionInfo.StartMonth {...props} />
          -
          <EntryScope.Properties.ConstructionInfo.EndMonth {...props} />
        </FieldValue>
      </Field>
    </VisibilityOptions.IfVisible>
  )
}

/**
 */
export const PropertyType = ({ ...props }) => {
  const { Entry: EntryScope } = Scope.useScope()
  const { formatFieldLabel, formatPropertyTypes } = Literals.useLiterals()

  return (
    <VisibilityOptions.IfVisible name="propertyType">
      <Field>
        <FieldLabel>{formatFieldLabel('PropertyType')}:</FieldLabel>
        <FieldValue>
          <EntryScope.Properties.PropertyInfo.PropertyType
            formatter={formatPropertyTypes}
            {...props}
          />
        </FieldValue>
      </Field>
    </VisibilityOptions.IfVisible>
  )
}

/**
 */
export const ConstructionType = ({ ...props }) => {
  const { Entry: EntryScope } = Scope.useScope()
  const { formatFieldLabel, formatConstructionTypes } = Literals.useLiterals()

  return (
    <VisibilityOptions.IfVisible name="constructionType">
      <Field>
        <FieldLabel>{formatFieldLabel('ConstructionType')}:</FieldLabel>
        <FieldValue>
          <EntryScope.Properties.ConstructionInfo.ConstructionType
            formatter={formatConstructionTypes}
            {...props}
          />
        </FieldValue>
      </Field>
    </VisibilityOptions.IfVisible>
  )
}

/**
 */
export const PropertyArea = ({ ...props }) => {
  const { Entry: EntryScope } = Scope.useScope()
  const { formatFieldLabel, formatPropertyArea } = Literals.useLiterals()

  return (
    <VisibilityOptions.IfVisible name="propertyArea">
      <Field>
        <FieldLabel>{formatFieldLabel('PropertyArea')}:</FieldLabel>
        <FieldValue>
          <EntryScope.Properties.PropertyInfo.Area
            formatter={formatPropertyArea}
            {...props}
          />
        </FieldValue>
      </Field>
    </VisibilityOptions.IfVisible>
  )
}

/**
 */
export const Floors = ({ ...props }) => {
  const { Entry: EntryScope } = Scope.useScope()
  const { formatFieldLabel, formatFieldFormat } = Literals.useLiterals()

  return (
    <VisibilityOptions.IfVisible name="floors">
      <Field>
        <FieldLabel>{formatFieldLabel('Floors')} :</FieldLabel>
        <FieldValue>
          {formatFieldFormat('GroundFloors.Prefix')}
          <EntryScope.Properties.PropertyInfo.GroundFloors {...props} />
          {formatFieldFormat('GroundFloors.Suffix')}/
          {formatFieldFormat('BasementFloors.Prefix')}
          <EntryScope.Properties.PropertyInfo.BasementFloors {...props} />
          {formatFieldFormat('BasementFloors.Suffix')}
        </FieldValue>
      </Field>
    </VisibilityOptions.IfVisible>
  )
}

/**
 */
export const CreatedAt = ({ ...props }) => {
  const { Entry: EntryScope } = Scope.useScope()
  const { formatFieldLabel } = Literals.useLiterals()

  return (
    <VisibilityOptions.IfVisible name="createdAt">
      <Field>
        <FieldLabel>{formatFieldLabel('CreatedAt')}:</FieldLabel>
        <FieldValue>
          <EntryScope.Properties.CreatedAt {...props} />
        </FieldValue>
      </Field>
    </VisibilityOptions.IfVisible>
  )
}

/**
 */
export const UpdatedAt = ({ ...props }) => {
  const { Entry: EntryScope } = Scope.useScope()
  const { formatFieldLabel } = Literals.useLiterals()

  return (
    <VisibilityOptions.IfVisible name="updatedAt">
      <Field>
        <FieldLabel>{formatFieldLabel('UpdatedAt')}:</FieldLabel>
        <FieldValue>
          <EntryScope.Properties.UpdatedAt {...props} />
        </FieldValue>
      </Field>
    </VisibilityOptions.IfVisible>
  )
}
