import React from 'react'

import * as Routes from '@arch-log/webapp.shared/Routes'
import * as Modal from '@arch-log/webapp.atomics/components/Modal'
import * as Content from './Content'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'

/**
 */
export const useActions = () => {
  const { redirectTo } = useNavigate()
  const { contents, push, hide } = React.useContext(Modal.Context)

  const showModal = ({ project }) => {
    push(() => (
      <>
        <Content.Flow
          project={project}
          onComplete={() => {
            redirectTo(Routes.Project.toProjectList())

            hide()
          }}
          onCancel={() => hide()}
        />
      </>
    ))
  }

  return {
    showModal,
    closeModal: hide,
  }
}
