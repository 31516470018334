import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = ({
  components: {
    Layout: {
      Wrapper = Layout.Wrapper,
      Header = Layout.Header,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
  } = {},
}) => (
  <Wrapper>
    <Header>
      <Containers.Title />
    </Header>
    <Body>
      <Containers.FieldProperties />
    </Body>
    <Footer>
      <Containers.ProjectEdit />
    </Footer>
  </Wrapper>
)
