import React from 'react'

import { useFormContext } from 'react-hook-form'

import * as FieldNames from './FieldNames'
import * as Inputs from '@arch-log/webapp.modules/utils/components/FormTemplate/Field/InputRenders'
import * as FieldValidators from './Field/Validators'

/**
 */
export const Password = ({ render: Renderer = Inputs.Password, ...props }) => {
  const { register } = useFormContext()

  return (
    <Renderer
      placeholder="Password"
      {...register(FieldNames.Password, {
        validate: FieldValidators.validatePassword,
      })}
      {...props}
    />
  )
}
