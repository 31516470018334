import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Layout from './Layout'
import * as Fields from './Fields'

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    components: {
      Layout: { Container = Layout.Container, Row = Layout.Row, PropertyRows = Layout.PropertyRows} = {},
    } = {},
  }) => (
    <Container>
      <Row>
        <Fields.Label />
      </Row>
      <Row>
        <Fields.Address />
      </Row>
      <PropertyRows>
        <Fields.ConstructionMonth />
        <Fields.PropertyType />
        <Fields.ConstructionType />
        <Fields.PropertyArea />
        <Fields.Floors />
      </PropertyRows>
    </Container>
  ),
)
