import React from 'react'

import * as layouts from './layouts.module.scss'

/**
 */
export const Wrapper = ({ children }) => {
  return <div className={layouts.Wrapper}>{children}</div>
}

/**
 */
export const Header = ({ children }) => {
  return <div className={layouts.Header}>{children}</div>
}

/**
 */
export const Body = ({ children }) => {
  return <div className={layouts.Body}>{children}</div>
}

/**
 */
export const Footer = ({ children }) => {
  return <div className={layouts.Footer}>{children}</div>
}

/**
 */
export const Section = ({ children }) => {
  return <div className={layouts.Section}>{children}</div>
}

/**
 */
export const Title = ({ children }) => {
  return <h3 className={layouts.Title}>{children}</h3>
}

/**
 */
export const SectionTitle = ({ children }) => {
  return <h4 className={layouts.SectionTitle}>{children}</h4>
}

/**
 */
export const Description = ({ children }) => {
  return <p className={layouts.Description}>{children}</p>
}

/**
 */
export const SectionDescription = ({ children }) => {
  return <p className={layouts.SectionDescription}>{children}</p>
}
