import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const DeleteDescription = translated('setting')(
  'Setting.Delete.Description',
)

/**
 */
export const CloneDescription = translated('setting')(
  'Setting.Clone.Description',
)

/**
 */
export const Title = translated('setting')('Setting.Title')
