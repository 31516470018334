import React from 'react'

import { Loader } from '@arch-log/webapp.atomics/components/Loader'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as ProjectExtention from '@arch-log/webapp.modules/project-extention.v2/components'
import * as ProjectExtentionConsts from '@arch-log/webapp.modules/project-extention.v2/consts'

export * from './Sections/Containers'

/**
 */
export const ProjectExtentionLoader = ({ children }) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()
  const [load, { loading }] = ProjectExtention.Remote.Queries.Entry.useLoad()

  React.useEffect(() => {
    load({
      variables: {
        projectId: projectEntry?.uuid,
        name: ProjectExtentionConsts.ProjectExtension_Name,
      },
    })
  }, [])

  if (loading) {
    return <Loader>project extention loading</Loader>
  }

  return <>{children}</>
}
