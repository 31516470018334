import React from 'react'

import * as styles from './styles.module.scss'
import { css } from '@emotion/react'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const Fields = ({ children }) => (
  <div className={styles.Fields}>
    <div>{children}</div>
  </div>
)

/**
 */
export const Links = ({ children }) => <>{children}</>

/**
 */
export const DetailFieldSection = ({ children }) => (
  <div className={styles.DetailsSection}>{children}</div>
)

export const Row = ({ children }) => (
  <div className={styles.Row}>{children}</div>
)

export const PropertyRows = ({ children }) => (
  <div
    css={css`
      display: grid;
      grid-template-columns: repeat(auto-fill, 300px);
      justify-content: space-between;
    `}
  >
    {children}
  </div>
)
