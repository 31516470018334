import React from 'react'

import * as styles from './layout.module.scss'
import { css } from '@emotion/react'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const Footer = ({ children }) => (
  <div className={styles.Footer}>{children}</div>
)

/**
 */
export const Body = ({ children }) => (
  <div className={styles.Body}>{children}</div>
)

/**
 */
export const BodySection = ({ children }) => (
  <div
    className={styles.BodySection}
    css={css`
      width: 100%;
      padding: 10px;
      margin-top: 20px;
    `}
  >
    {children}
  </div>
)

export const FormRow = ({ children }) => (
  <div
    css={css`
      width: 100%;
      > label {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        width: 100%;
        padding: 5px;

        background: transparent;
        &:hover {
          background: rgba(255, 0, 0, 0.05);
        }
      }
    `}
  >
    {children}
  </div>
)
