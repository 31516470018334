import React from 'react'

import * as styles from './styles.module.scss'
export * from './Sections/Layout'

/**
 */
export const Wrapper = ({ children }) => (
  <div className={styles.Wrapper}>{children}</div>
)

/**
 */
export const Section = ({ children }) => (
  <div className={styles.Section}>{children}</div>
)

/**
 */
export const Title = ({ children }) => (
  <div className={styles.TitleContainer}>
    <h2 className={styles.Title}>{children}</h2>
  </div>
)
