import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const ConfirmMessage = translated('setting')(
  'Setting.LockSystem.Trial.ConfirmMessage',
)

/**
 */
export const Cancel = translated('setting')('Setting.LockSystem.Trial.Cancel')

/**
 */
export const Accept = translated('setting')('Setting.LockSystem.Trial.Accept')

/**
 */
export const Progress = translated('common')('Progress')

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('setting')

  const formatActionError = (key) => t(`Setting.LockSystem.Trial.${key}`)

  return {
    formatActionError,
  }
}
