import React from 'react'

import { css } from '@emotion/react'

import * as styles from './styles.module.scss'

/**
 */
export const Description = ({ children }) => (
  <div className={styles.Description}>{children}</div>
)

/**
 */
export const CancelButton = ({ onClick, children }) => (
  <button className={styles.CancelButton} type="button" onClick={onClick}>
    {children}
  </button>
)

/**
 */
export const SubmitButton = ({ children }) => (
  <button type="submit" className={styles.SubmitButton}>
    {children}
  </button>
)

/**
 */
export const CloseButton = ({ onClick, children }) => (
  <button className={styles.CloseButton} type="button" onClick={onClick}>
    {children}
  </button>
)

/**
 */
export const ActionError = ({ children }) => {
  return <p className={styles.ActionError}>{children}</p>
}

export const NoticeWrapper = ({ children }) => (
  <span
    css={css`
      font-size: 0.8rem;
      color: #999999;
      padding: 10px 20px;
      display: inline-block;
    `}
  >
    {children}
  </span>
)
