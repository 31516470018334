import React from 'react'

import { Context } from './Context'

/**
 */
export const IfVisible = ({ name, children }) => {
  const { visibilities } = React.useContext(Context)

  if (visibilities[name]) {
    return <>{children}</>
  }

  return null
}
