import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Delete = translated('setting')('Setting.Delete.Button')

/**
 */
export const Clone = translated('setting')('Setting.Clone.Button')
