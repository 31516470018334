import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Description = translated('setting')(
  'Setting.Delete.Modal.Description',
)

/**
 */
export const Cancel = translated('setting')('Setting.Delete.Modal.Cancel')

/**
 */
export const Submit = translated('setting')('Setting.Delete.Modal.Submit')

/**
 */
export const Placeholder = translated('setting')(
  'Setting.Delete.Modal.Password',
)

/**
 */
export const ConfirmMessage = translated('setting')(
  'Setting.Delete.Confirm.Message',
)

/**
 */
export const Accept = translated('setting')('Setting.Delete.Confirm.Accept')

/**
 */
export const Progress = translated('setting')('Setting.Delete.Progress')

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('setting')

  const formatFormError = (key, ...options) =>
    t(`Setting.Delete.${key}`, ...options)

  const formatFieldError = (key, ...options) =>
    t(`Setting.Delete.Form.Error.${key}`, ...options)

  return {
    formatFormError,
    formatFieldError,
  }
}
