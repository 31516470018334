import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const ConfirmMessage = ({ children, ...props }) => (
  <div {...props} className={styles.ConfirmMessage}>
    {children}
  </div>
)

/**
 */
export const ActionErrorMessage = ({ children, ...props }) => (
  <div {...props} className={styles.ActionErrorMessage}>
    {children}
  </div>
)

/**
 */
export const AcceptButton = ({ children, onClick, ...props }) => (
  <button
    type="button"
    className={styles.AcceptButton}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
)

/**
 */
export const CancelButton = ({ onClick, children }) => (
  <button className={styles.CancelButton} type="button" onClick={onClick}>
    {children}
  </button>
)

/**
 */
export const SubmitButton = ({ children }) => (
  <button type="submit" className={styles.SubmitButton}>
    {children}
  </button>
)
