import React from 'react'

import { withLanguage } from '@arch-log/webapp.modules/i18n/components/withLanguage'

/**
 */
export const Request = withLanguage(({ language }) => {
  switch (language) {
    case 'en':
      return (
        <>
          By applying for the completion information management function, you
          can use the following extended functions. (Apply for 10 years)
        </>
      )
    default:
      return (
        <>
          竣工情報ロックシステムにお申込みいただくことで、下記拡張機能をご利用いただけます。
          (10年分のお申込みとなります)
        </>
      )
  }
})

/**
 */
export const PropertyDatabaseFeature = withLanguage(({ language }) => {
  switch (language) {
    case 'en':
      return (
        <>
          Detailed property information, inspection and periodic inspection
          history, etc. can be registered as project information. It also has a
          file upload function, so you can save drawing and photo data.
        </>
      )
    default:
      return (
        <>
          物件の詳細情報、検査や定期点検履歴などをプロジェクト情報として登録しておくことができます。
          またファイルアップロード機能も備えており、図面や写真のデータを保存することもできます。
        </>
      )
  }
})

/**
 */
export const ProjectLockFeature = withLanguage(({ language }) => {
  switch (language) {
    case 'en':
      return (
        <>
          By locking product information registered in the project, product
          information due to information changes and discontinuation Prevents
          display changes. (If the construction date is set for the project, it
          will be automatically locked at the beginning of the construction
          date. Manual locking and unlocking is also possible. )
        </>
      )
    default:
      return (
        <>
          プロジェクトに登録されている製品情報をロックすることで、情報変更および廃盤による製品情報
          表示の変更を防ぎます。
          (プロジェクトに施工年月が設定されている場合、施工年月の月初に自動的ロックされます。また
          手動でのロック、ロック解除も可能です。)
        </>
      )
  }
})

/**
 */
export const MaintenanceFeature = withLanguage(({ language }) => {
  switch (language) {
    case 'en':
      return (
        <>
          This function notifies target users by e-mail according to a preset
          schedule. Mainly, it is possible to set the maintenance cycle for each
          product and manage the schedule for inspection and maintenance. Is
          possible.
        </>
      )
    default:
      return (
        <>
          事前に設定したスケジュールに応じて、対象のユーザーにメールでの通知を行う機能です。
          主には製品ごとにメンテナンス周期を設定し、点検や保守が必要な日程を管理することが
          可能です。
        </>
      )
  }
})
