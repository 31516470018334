import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const RequestTitle = translated('setting')(
  'Setting.LockSystem.Titles.Request',
)

/**
 */
export const PropertyDatabaseFeatureTitle = translated('setting')(
  'Setting.LockSystem.Titles.PropertyDatabaseFeature',
)

/**
 */
export const ProjectLockFeatureTitle = translated('setting')(
  'Setting.LockSystem.Titles.ProjectLockFeature',
)

/**
 */
export const MaintenanceFeatureTitle = translated('setting')(
  'Setting.LockSystem.Titles.MaintenanceFeature',
)

/**
 */
export const RequestPrice = translated('setting')(
  'Setting.LockSystem.RequestPrice',
)

/**
 */
export const RequestedAt = translated('setting')(
  'Setting.LockSystem.RequestedAt',
)

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation(['setting'])

  const formatRequestLabel = (state) => {
    return t(`Setting.LockSystem.Request.${state}`)
  }

  return {
    formatRequestLabel,
  }
}
