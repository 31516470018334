import React from 'react'

import * as ProjectExtention from '@arch-log/webapp.modules/project-extention.v2/components'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Modal from '@arch-log/webapp.atomics/components/Modal'
import * as Content from './Content'

/**
 */
export const useActions = () => {
  const {
    refetch: projectExtentionRefetch,
  } = ProjectExtention.Remote.Queries.Entry.useContext()
  const {
    refetch: projectMyEntriesRefetch,
  } = Project.Remote.Queries.MyEntries.useContext()
  const {
    refetch: projectEntryRefetch,
  } = Project.Remote.Queries.Entry.useContext()

  const { show, hide } = React.useContext(Modal.Context)

  const showModal = ({ projectId, projectUUId }) => {
    show(() => (
      <Content.Flow
        projectId={projectId}
        projectUUId={projectUUId}
        onRefetch={async () => {
          return Promise.all([
            projectExtentionRefetch(),
            projectMyEntriesRefetch(),
            projectEntryRefetch(),
          ])
        }}
        onComplete={() => {
          hide()
        }}
        onCancel={() => hide()}
      />
    ))
  }

  return {
    showModal,
    closeModal: hide,
  }
}
