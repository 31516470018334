import React from 'react'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Roles from '@arch-log/webapp.modules/project.authorization.v2/roles'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as Config from '@arch-log/webapp.modules/config/components'
import * as Containers from './Containers'
import * as Layout from './Layout'
import * as Sections from './Sections'
import * as Literals from './Literals'

/**
 */
export const Default = ({
  components: {
    Layout: {
      Wrapper = Layout.Wrapper,
      Title = Layout.Title,
      ProjectInfoSection = Layout.Section,
      CloneSection = Layout.Section,
      DeleteSection = Layout.Section,
      LockSystemSection = Layout.Section,
    } = {},
  } = {},
}) => {
  return (
    <Wrapper>
      <Title>
        <Literals.Title />
      </Title>
      {/* projectInfo */}
      <ProjectInfoSection>
        <Sections.ProjectInfoSection />
      </ProjectInfoSection>
      {/* cloneSection */}
      <ProjectAuthorization.ContentForPermissions
        permission={Permissions.Clone}
      >
        <CloneSection>
          <Sections.CloneSection />
        </CloneSection>
      </ProjectAuthorization.ContentForPermissions>
      {/* deleteSection */}
      <ProjectAuthorization.ContentForPermissions
        permission={Permissions.Delete}
      >
        <DeleteSection>
          <Sections.DeleteSection />
        </DeleteSection>
      </ProjectAuthorization.ContentForPermissions>

      {/* lockSystemRequestSection */}
      <Config.IfFeatureEnabled name={'buildingCompletionPremium'}>
        <ProjectAuthorization.ContentForRole role={Roles.Owner}>
          <Containers.ProjectExtentionLoader>
            <LockSystemSection>
              <Sections.LockSystemRequestSection />
            </LockSystemSection>
          </Containers.ProjectExtentionLoader>
        </ProjectAuthorization.ContentForRole>
      </Config.IfFeatureEnabled>
    </Wrapper>
  )
}
