import React from 'react'

import { useTranslation } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('module.project')

  const formatFieldLabel = (key, ...options) => {
    return t(`Field.Labels.${key ?? 'unknown'}`, ...options)
  }

  const formatFieldFormat = (key, ...options) => {
    return t(`Field.Formats.${key ?? 'unknown'}`, ...options)
  }

  const formatPropertyArea = (key, ...options) =>
    t(`module.project:PropertyArea.${key}`, ...options)

  const formatPropertyTypes = (key, ...options) =>
    t(`module.project:PropertyTypes.${key}`, ...options)

  const formatConstructionTypes = (key, ...options) =>
    t(`module.project:ConstructionTypes.${key}`, ...options)

  return {
    formatFieldLabel,
    formatFieldFormat,
    formatPropertyArea,
    formatPropertyTypes,
    formatConstructionTypes,
  }
}
